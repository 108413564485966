import React, {
  Component,
} from 'react';
import { PageProps } from 'gatsby';
import { navigate } from '@reach/router';
import { getComponent, blokToComponent } from '../components';
import { AdobeAnalytics } from '../components/custom/adobe-analytics';
import { Analytics } from '../components/custom/analytics';
import {
  DomService,
  StoryblokService,
  LanguageService,
  StringService,
} from '../services';
import { SEO } from '../components/custom/seo';
import { EntryData } from './types';
import { Hotjar } from '../components/custom/hotjar';
import { MetaRobotsContent } from '../components/custom/meta-robots-content';
import { FontOverride } from '../components/custom/font-override';
import { maskAssetUrl } from '../components/props/asset';
import { Tracking } from '../tracking';
import { getTrackingContextProps } from '../tracking/context';
import { RocheHeader } from '../components/custom/roche-header';

interface StoryblokEntryProps extends PageProps {
  pageContext: EntryData;
}

type StoryblokEntryState = EntryData;
type RouterState = {
  urlMask?: string
};

const RocheGlobalConfig = getComponent('roche-global-config') as React.ElementType;
const Breadcrumbs = getComponent('roche-breadcrumbs') as React.ElementType;

const parseEntryData = ({ pageContext }: StoryblokEntryProps): StoryblokEntryState => {
  const { story, defaultLanguageHome } = pageContext;

  return {
    ...pageContext,
    ...DomService.getGlobalConfig(story, defaultLanguageHome),
  };
};

// eslint-disable-next-line import/no-default-export
export default class StoryblokEntry extends Component<StoryblokEntryProps, StoryblokEntryState> {
  public static getDerivedStateFromProps(
    props: StoryblokEntryProps, state: StoryblokEntryState,
  ): StoryblokEntryState {
    return state.story.uuid !== props.pageContext.story.uuid
      ? parseEntryData(props)
      : null;
  }

  public constructor(props: StoryblokEntryProps) {
    super(props);
    this.state = parseEntryData(props);
  }

  public componentDidMount(): void {
    this.handleUrlMask();
    this.handle404();
    window.addEventListener('rocheLoginComplete', () => StoryblokService.redirect());

    DomService.activateConsentScript();
    DomService.activateDatadogMonitoring();
  }

  public render(): JSX.Element {
    const {
      breadcrumbs,
      emergencyBanner,
      footer,
      home,
      navigation,
      onClickNotice,
      story,
      globalComponents: {
        content: {
          global_injected_markup: globalInjectedMarkup,
        },
      },
      ...globalConfig
    } = this.state;

    const canShowNavigation = !story.content.hide_navigation && navigation;
    const shouldFixHeader = story.content.fixed_header;
    const canShowFooter = !story.content.hide_footer;
    const canShowStockPrice = !story.content.hide_stock_price;
    const isStoryTypeContent = story.content.component === 'story';
    const canShowBreadcrumbs = !story.content.hide_breadcrumbs && !isStoryTypeContent;
    const displayMicrositeHeader = story.content.microsite_header;
    const canShowMicrositeHeaderTitle = story.content.microsite_title?.[0];
    const shouldShowHeader = !story.content.remove_header;
    const shouldHideFromSearchEngines = story.content.hide_search_engines;
    const disableIndexing = process.env.GATSBY_BLOCK_SE_INDEXING === 'true' ?? false;
    const metaTags = Object.entries(story.content.meta_tags ?? {})
      .reduce((accumulator, [key, value]) => ({
        ...accumulator,
        [key]: maskAssetUrl(value as string),
      }), {});

    // remove injected markup from global config
    // eslint-disable-next-line
    // @ts-expect-error
    const removed = globalInjectedMarkup; // eslint-disable-line
    return (
      <Tracking {...getTrackingContextProps(this.state)}>
        <FontOverride overrides={this.props.pageContext.fontOverwrites} />
        <Analytics />
        <AdobeAnalytics />
        <Hotjar />
        {(disableIndexing || shouldHideFromSearchEngines) && <MetaRobotsContent />}
        <SEO
          {...metaTags}
          lang={story.lang}
          slug={story.full_slug}
          authorized_roles={story.content.authorized_roles}
          contentType={story.content?.teaser_type !== undefined ? 'stories' : 'pages'}
          story_type={story.content.teaser_type || 'none'}
          teaser_image={maskAssetUrl(story.content?.teaser_image?.[0]?.src?.filename || '')}
          first_published_at={story.first_published_at}
          teaser_title={story.content.teaser_title}
          teaser_description={story.content.teaser_description}
        ></SEO>
        <RocheGlobalConfig {...globalConfig}></RocheGlobalConfig>
        <roche-animation-manager />

        {shouldShowHeader
          && <RocheHeader
            home={LanguageService.getHomePageUrl(home)}
            navigation={navigation?.content as any}
            showNavigation={!!canShowNavigation}
            isMicrosite={displayMicrositeHeader}
            isFixed={shouldFixHeader}
            alternates={this.state.alternates}>

            {canShowMicrositeHeaderTitle
              && blokToComponent({ blok: canShowMicrositeHeaderTitle, slot: 'microsite-title', getComponent })
            }
          </RocheHeader>}

        {canShowBreadcrumbs && !displayMicrositeHeader && <Breadcrumbs list={breadcrumbs} />}
        {blokToComponent({ blok: story.content, getComponent, storyId: story.id })}
        {canShowFooter && footer
          && blokToComponent({ blok: { ...footer.content, canShowStockPrice }, getComponent })}
        {onClickNotice && blokToComponent({ blok: onClickNotice.content, getComponent })}
        {emergencyBanner && blokToComponent({ blok: emergencyBanner.content, getComponent })}
        <roche-fouc-preventer />
      </Tracking>
    );
  }

  private handle404(): void {
    const { pageResources, location } = this.props;
    const is404Page = pageResources.page.path === '/404.html';

    if (!is404Page) {
      return;
    }

    const unreachableRouteLocale = LanguageService.getLocaleFromSlug(location.pathname);
    const localized404Page = this.state.alternates
      .find(({ locale }) => locale === unreachableRouteLocale);

    if (!localized404Page) {
      return;
    }

    const urlMask = window.location.href;

    // Use setTimeout to navigate to the localized 404 page
    window.setTimeout(() => {
      const sanitizedUrl = StringService.sanitizeUrl(`/${localized404Page.url}`);
      navigate(sanitizedUrl, { replace: true, state: { urlMask } });
    }, 10);
  }

  private handleUrlMask(): void {
    const { urlMask } = (this.props.location.state || {}) as RouterState;
    return urlMask ? window.history.replaceState('', '', urlMask) : undefined;
  }
}

export { Head } from '../components/custom/head';
